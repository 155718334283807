import axios from 'axios'
import { Message } from 'element-ui';

import {
  setToken,
  getToken,
  getDevToken,
} from '@/utils/tools';

const addErrorLog = errorInfo => {
  if (!errorInfo) return;
  const {
    // statusText,
    // status,
    request: {
      responseURL
    }
  } = errorInfo
  if (!responseURL.includes('save_error_logger')) {
    // store.dispatch('addErrorLog', info)
  }
}
// 测试
let contentType = 'application/json;charset=UTF-8'

let originURl, metadatatoken
let projectInfo = JSON.parse(localStorage.getItem('projectInfo'))

if (projectInfo) {
  originURl = projectInfo.originURl
  metadatatoken = projectInfo.metadatatoken
} else {
  originURl = "https://saas-api.bimcc.net"
  metadatatoken = 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06'
}




// 请求队列
// const pending = [];
// const cancelToken = axios.CancelToken
// const removePending = (config) => {
//   for (let p in pending) {
//     if (Object.entries(pending[p].u).toString() === Object.entries(config).toString()) {
//       pending[p].f(); // 取消
//       pending.splice(p, 1);
//     }
//   }
// }

class HttpRequest {
  constructor(baseUrl = 'https://saas-api.bimcc.net') {
    // 动态获取当前服务器环境
    const { pathname } = window.location;
    this.baseUrl = baseUrl
    if (pathname === '/modify-page' || pathname === '/page-view') {
      // 对于页面配置需要根据当前服务器进行请求， 对于保存 模板等则无需更改
      try {
        const targetServer = JSON.parse(localStorage.getItem('targetServer'));
        console.log(targetServer, '----targetServe----')
        // this.baseUrl = targetServer.originURl || 'https://dwh_api.bimcc.net';
      } catch (err) {
        console.log(err, '-----err----')
      }
    }

    // 根据选择的服务器设置请求连接
    this.baseUrl = originURl

    this.queue = {}
  }
  getInsideConfig() {
    let config = {
      baseURL: this.baseUrl,
      headers: {
        'metadatatoken': metadatatoken,
        'Content-Type': contentType,
        'bimcctoken': getToken(),
        'dev-metadatatoken': getDevToken()
      }
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // console.log(this.queue, 'this.queue');
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    let method = ''
    instance.interceptors.request.use(config => {
      // console.log(config, '00000---请求拦截----00000')
      // 清除
      // removePending(config.data);
      // 添加记录
      // config.cancelToken = new cancelToken(c => {
      //   pending.push({
      //     u: config.data,
      //     f: c
      //   })
      // });
      method = config.method
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      // console.log(res.config, '11111---响应拦截----111')
      // removePending(res.config.data);
      this.destroy(url)
      const {
        data,
        status
      } = res
      return {
        data,
        status
      }
    }, error => {
      this.destroy(url)
      addErrorLog(error.response)
      return Promise.reject(error)
    })
    instance.interceptors.response.use((res) => {
      let {data} = res
      const is = this.destroy(url)
      if (!is) {
        // setTimeout({}, 500)
      }
      if (data.msg == 'SUCCESS') {
        return res
      }
      if (data.code == undefined) {
        return res
      } else if (data.code !== 200) {
        if (data.code === 401) { //  || data.code === 0
          // bimccToken
          // eventBus.$emit("showChangeArchi");
        } else if(data.code === 402) {
          // devToken
          // 判断当前所在仓库是否测试服务器
          if (originURl == 'https://saas-api.bimcc.net') {
            // 测试服务器跳转登录页
            Message.error({message: data.msg});
            sessionStorage.clear();
            localStorage.removeItem('devUserInfo');
            localStorage.removeItem('projectInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userRoles');
            localStorage.removeItem('targetArchi');
            localStorage.removeItem('mockUserInfo');
            setToken('');
            // 跳转登录页
            console.log('----111--测试服务器跳转登录页---http.js');
            window.location.href = '/login'
          } else {
            // 非测试服务器报错
            Message.error({message: '非测试服务器禁止增删改操作'});
            return
          }
        }else if (data.code === 422) {
          if (data.msg) {
            Message({
              message: '数据校验失败！',
              type: 'error'
            })
        } else if (data.code === 500) {
            sessionStorage.clear()
            setToken('')
            Message({
              message: '未登录或登录失效，请登录!',
              type: 'error'
            })
          }
          Message({
            message: data.msg,
            type: 'error'
          })
        } else {
          if (data.msg) {
            Message({
              message: data.msg,
              type: 'error'
            })
          }
        }
        return res
      } else {
        if (data.msg && method != 'get') {
          // console.log(11, 'success');
        }
        return res
      }
    }, (error) => {
      if (error.message) {
        Message({
          message: '服务器内部错误',
          type: 'error'
        })
      }
      return Promise.reject(error)
    })
  }
  request(options) {
    const instance = axios.create({
      baseUrl: 'https://saas-api.bimcc.net/api/metadata/',
      timeout: '600000',
      headers: {
          'Content-Type': contentType,
          'metadatatoken' : metadatatoken
      },
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options, {
      cancelToken: ''
    })
  }
}
export default HttpRequest
