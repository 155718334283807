/*
 * @Author: hw
 * @Date: 2022-09-06 14:10:12
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
const baseRoutes = [
  {
    path: '/web-page',
    name: 'WebPage',
    component: () => import('@/views/webPage/webPage'),
    meta: {
      title: '系统设置',
      icon: 'icongis_xitongshezhi',
    },
    children: [
      {
        path: "/templates/safe-module",
        name: "SafeModule",
        component: () =>
          import("@/views/templateStore/childPages/SafeModule.vue"),
        meta: {
          title: "模板市场模块",
        },
      },
    ]
  }
  
];

export default baseRoutes;
