/*
 * @Author: zx
 * @Date: 2022-08-31 09:10:12
 * @LastEditTime: 2024-07-08 09:27:23
 * @Description:
 * @LastEditors: yl 1871883713@qq.com
 */
const baseRoutes = [
  {
    path: '/web-page',
    name: 'WebPage',
    component: () => import('@/views/webPage/webPage'),
    meta: {
      title: '官网',
      icon: 'icongis_xitongshezhi',
    },
    children: [
      {
        path: "/products/products-saas",
        name: "ProductsSaas",
        component: () =>
          import("@/views/productsSaas/index.vue"),
        meta: {
          title: "Saas 版"
        }
      },
      {
        path: "/products/professional-edition",
        name: "ProfessionalEdition",
        component: () =>
          import("@/views/professionalEdition/index.vue"),
        meta: {
          title: "专业版"
        }
      },
      {
        path: "/products/form-engine",
        name: "FormEngine",
        component: () =>
          import("@/views/formEngine/index.vue"),
        meta: {
          title: "表单引擎",
        },
      },
      {
        path: "/products/no-code",
        name: "NoCode",
        component: () => import("@/views/noCode/index.vue"),
        meta: {
          title: "无代码引擎",
        },
      },
      {
        path: "/products/data-table",
        name: "DataTable",
        component: () => import("@/views/dataTable/index.vue"),
        meta: {
          title: "数据表格引擎",
        },
      },
      {
        path: "/products/data-modeling",
        name: "DataModeling",
        component: () =>
          import("@/views/dataModeling/index.vue"),
        meta: {
          title: "元数据建模引擎",
        },
      },
      {
        path: "/products/process-engine",
        name: "ProcessEngine",
        component: () =>
          import("@/views/processEngine/index.vue"),
        meta: {
          title: "流程引擎",
        },
      },
      {
        path: "/products/digital-twin",
        name: "DigitalTwinEngine",
        component: () =>
          import("@/views/digitalTwinEngine/index.vue"),
        meta: {
          title: "数字孪生引擎",
        },
      },
      {
        path: "/products/automation-engine",
        name: "AutomationEngine",
        component: () =>
          import("@/views/automationEngine/index.vue"),
        meta: {
          title: "自动化任务引擎",
        },
      },
      {
        path: "/products/panorama",
        name: "Panorama",
        component: () =>
          import("@/views/panorama/index.vue"),
        meta: {
          title: "全景引擎",
        },
      },
      {
        path: "/products/blueprint",
        name: "Blueprint",
        component: () =>
          import("@/views/blueprint/index.vue"),
        meta: {
          title: "事件流蓝图",
        },
      },
      {
        path: "/products/slide",
        name: "Slide",
        component: () =>
          import("@/views/programme/childPages/Slide.vue"),
        meta: {
          title: "PPT",
        },
      },
      {
        path: "/products/page-editor",
        name: "PageEditor",
        component: () =>
          import("@/views/programme/childPages/PageEditor.vue"),
        meta: {
          title: "页面编辑器",
        },
      },
      {
        path: "/products/cooperation-plan",
        name: "CooperationPlan",
        component: () =>
          import("@/views/cooperationPlan/index.vue"),
        meta: {
          title: "生态合作计划"
        }
      }
    ]
  }
];

export default baseRoutes;
