/*
 * @Description: 表单渲染器请求
 * @Author: luocheng
 * @Date: 2021-12-27 16:35:35
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-11-09 15:05:15
 */
import { axios } from './request';
//表单渲染器
export const formRequest = (method,url,data = {}) => {
	// console.log(url, 'formRequest-url')
	// const requestUrl = url.replace(/api\//g, '')
	if(method === 'get' || method === 'GET'){
		return axios.request({
			url,
			method: method,
			params: data
		})
	} else {
		return axios.request({
			url,
			method: method,
			data: data
		})
	}
};