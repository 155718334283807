/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-11-09 14:12:47
 * @LastEditors: 朱廷果
 * @LastEditTime: 2023-04-19 16:36:46
 * @FilePath: \saas_front\src\router\website\official.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const baseRoutes = [
  {
    path: "/web-page",
    name: "WebPage",
    component: () => import("@/views/webPage/webPage.vue"),
    meta: {
      title: "官网",
      icon: "icongis_xitongshezhi",
    },
    children: [
      {
        path: "/web-page/home",
        name: "HomePage",
        component: () => import("@/views/homePage/homePage.vue"),
        meta: {
          title: "飞椽零代码开发平台",
        },
      },
      {
        path: "/web-page/custom-case",
        name: "CustomerCase",
        component: () =>
          import("@/views/customCase/CustomCase.vue"),
        meta: {
          title: "客户案例",
        },
      },
      {
        path: "/web-page/price",
        name: "Price",
        component: () => import("@/views/price/Price.vue"),
        meta: {
          title: "版本价格",
        },
      },
      {
        path: "/web-page/templates",
        name: "Template",
        component: () =>
          import("@/views/templateStore/index.vue"),
        meta: {
          title: "模板市场",
        },
      },
      {
        path: "/web-page/doc-center",
        name: "ZhuyunCollege",
        component: () =>
          import("@/views/docHelpCenter/DocHelpCenter.vue"),
        meta: {
          title: "筑云学院文档中心",
        },
      },
      {
        path: "/web-page/down-app",
        name: "DownApp",
        component: () => import("@/views/downApp/index.vue"),
        meta: {
          title: "下载App",
        },
      },
      {
        path: "/web-page/about-our",
        name: "AboutUs",
        component: () => import("@/views/aboutOur/AboutOur.vue"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/web-page/digital-twin",
        name: "DigitalTwin",
        component: () => import("@/views/digitalTwin/index.vue"),
        meta: {
          title: "数字孪生",
        },
      },
      {
        path: "/web-page/wisdom-site",
        name: "WisdomSite",
        component: () => import("@/views/wisdomSite/index.vue"),
        meta: {
          title: "智慧工地",
        },
      },
      {
        path: "/web-page/apply-demo",
        name: "ApplyDemo",
        component: () => import("@/views/applyDemo/index.vue"),
        meta: {
          title: "申请演示",
        },
      },
    ],
  },
];

export default baseRoutes;
