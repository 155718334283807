/*
 * @Description: 全局注册部分element组件
 * @Author: luocheng
 * @Date: 2021-08-10 15:35:37
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-11-10 16:13:31
 */
import Vue from 'vue';
import {
  Loading,
  Message,
  MessageBox,
  Button,
  Dialog,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  RadioGroup,
  Radio,
  Checkbox,
  Collapse,
  CollapseItem,
  Drawer,
  Switch,
  Row,
  Col,
  Table,
  TableColumn,
  Pagination,
  DatePicker

} from 'element-ui';

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Drawer);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Loading.directive);
Vue.use(DatePicker);

// Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
