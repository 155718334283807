/*
 * @Description: saas系统管理中心保存数据
 * @Author: zx
 * @Date: 2021-08-10 16:45:55
 * @LastEditors: zx
 * @LastEditTime: 2022-11-03 14:06:52
 */
import { deepCopy } from '@/utils/tools';

export default {
	state: {
		saasCurrentMenu: sessionStorage.getItem('saasCurrentMenu') ? JSON.parse(sessionStorage.getItem('saasCurrentMenu')) : {},
		systemStyle: localStorage.getItem('system_style') ? +localStorage.getItem('system_style') : 1,
		saasTargetArchi: localStorage.getItem('saasTargetArchi') ? JSON.parse(localStorage.getItem('saasTargetArchi')) : {},
		saasVersion: 2,
		indexCurrentTab: sessionStorage.getItem("indexCurrentTab") ? +sessionStorage.getItem("indexCurrentTab") : 0 
	},
	mutations: {
		/**
		 * @desc: 保存当前菜单数据
		 */
		setCurrentMenus(state, data) {
			if (Array.isArray(data) && data.length) {
				sessionStorage.setItem('saasCurrentMenu', JSON.stringify(data[data.length - 1]))
				state.saasCurrentMenu = deepCopy(data[data.length - 1])
			} else {
				sessionStorage.setItem('saasCurrentMenu', JSON.stringify(data))
				state.saasCurrentMenu = deepCopy(data)
			}
		},
		/**
		 * @desc: 设置当前样式
		 */
		setSystemStyle(state, data) {
			state.systemStyle = data
      localStorage.setItem('system_style', data)
		},
		/**
		 * @desc: 设置当前架构选中
		 */
		setSaasTargetArchi(state, data) {
			state.saasTargetArchi = data
      localStorage.setItem('saasTargetArchi', JSON.stringify(data))
		},
		/**
		 * @desc: 设置当前版本 企业版  个人版
		 */
		setSaasVersion(state, data) {
			state.saasVersion = +data
		},
		/**
		 * @desc: 设置官网当前tab
		 */
		setIndexCurrentTab(state, data) {
			state.indexCurrentTab = +data
      sessionStorage.setItem("indexCurrentTab", +data);
		},
	}
};
