/*
 * @Description: 工具函数
 * @Author: luocheng
 * @Date: 2021-08-10 16:41:38
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-12 16:38:40
 */
import Cookies from 'js-cookie';
export const TOKEN_KEY = 'bimcctoken';
/**
 * @desc: 设置token
 */
export const setToken = (token) => {
  Cookies.set(token)
};
/**
 * @desc: 获取token
 */
 export const getToken = () => {
	const token = Cookies.get(TOKEN_KEY);
	if (token != undefined && token != '') {
		return token;
	}
	return '';
};
export const DEV_TOKEN_KEY = 'dev_metadatatoken';
/**
 * @desc: 设置dev_token
 */

/**
 * @desc: 获取dev_token
 */
export const getDevToken = () => {
	const token = Cookies.get(DEV_TOKEN_KEY);
	if (token != undefined && token != '') {
		return token;
	}
	return '';
};
export function deepCopy(target) {
	if (typeof target == 'object') {
		const result = Array.isArray(target) ? [] : {};
		for (const key in target) {
			if (typeof target[key] == 'object' && target[key]) {
				result[key] = deepCopy(target[key]);
			} else {
				result[key] = target[key];
			}
		}
		return result;
	}
	return target;
}