/*
 * @Description:
 * @Author: luocheng
 * @Date: 2022-08-16 15:42:39
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-02-06 09:57:29
 */
import AppDownload from "@/views/appConfig/Index";
import AppConfig from "@/views/appConfig/Config";

const baseRoutes = [
  {
    path: "/knowledge/shower",
    name: "KnowledgeShower",
    component: () =>
      import("@/views/knowledgeBase/shower/index.vue"),
    meta: {
      title: "知识库详情",
      icon: "iconrichangpeixunguanli",
      hideInMenu: true,
    },
  },
  {
    path: "/app-download",
    meta: {
      title: "App下载",
    },
    name: "AppDownload",
    component: AppDownload,
  },
  {
    path: "/app-config",
    meta: {
      title: "App配置",
    },
    name: "AppConfig",
    component: AppConfig,
  },
  {
    path: '/share',
		meta: {
			title: '分享'
		},
		name: 'Share',
		component: () =>import('@/views/knowledgeBase/share/Login'),
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import('@/views/knowledgeBase/share/Index.vue'),
    meta: {
      title: '分享详情',
      icon: 'iconc-home'
    }
  },
  {
    path: '/appshareDetail',
    name: 'appshareDetail',
    component: () => import('@/views/knowledgeBase/share/appShare/Index.vue'),
    meta: {
      title: '分享详情',
      icon: 'iconc-home'
    }
  },
];
export default baseRoutes;
