/*
 * @Author: zx
 * @Date: 2022-08-31 09:10:12
 * @LastEditTime: 2022-12-07 14:34:39
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
 */
const baseRoutes = [
  {
    path: '/web-page',
    name: 'WebPage',
    component: () => import('@/views/webPage/webPage'),
    meta: {
      title: '系统设置',
      icon: 'icongis_xitongshezhi',
    },
    children: [
      {
        path: "/propramme/safe-edu",
        name: "SafeEdu",
        component: () =>
          import("@/views/programme/childPages/SafeEdu.vue"),
        meta: {
          title: "安全教育",
        },
      },
      {
        path: "/propramme/basic-abl",
        name: "BasicAbl",
        component: () =>
          import("@/views/programme/childPages/BasicAbl.vue"),
        meta: {
          title: "基础能力",
        },
      },
      {
        path: "/propramme/bim-gis",
        name: "BimGis",
        component: () =>
          import("@/views/programme/childPages/BimGis.vue"),
        meta: {
          title: "BIMGIS功能",
        },
      },
      {
        path: "/propramme/hse",
        name: "Hse",
        component: () =>
          import("@/views/programme/childPages/Hse.vue"),
        meta: {
          title: "安全环保管理",
        },
      },
      {
        path: "/propramme/data-fty",
        name: "DataFty",
        component: () =>
          import("@/views/programme/childPages/DataFty.vue"),
        meta: {
          title: "数据仓库",
        },
      },
      {
        path: "/propramme/wark-progress",
        name: "WarkProgress",
        component: () =>
          import("@/views/programme/childPages/WarkProgress.vue"),
        meta: {
          title: "施工进度",
        },
      },
      {
        path: "/propramme/smart-site",
        name: "SmartSite",
        component: () =>
          import("@/views/programme/childPages/SmartSite.vue"),
        meta: {
          title: "智慧工地",
        },
      },
      {
        path: "/propramme/device-manage",
        name: "DeviceManage",
        component: () =>
          import("@/views/programme/childPages/DeviceManage.vue"),
        meta: {
          title: "设备管理",
        },
      },
      {
        path: "/propramme/quality-manage",
        name: "QualityManage",
        component: () =>
          import("@/views/programme/childPages/QualityManage.vue"),
        meta: {
          title: "质量管理",
        },
      },
      {
        path: "/propramme/technical",
        name: "Hse",
        component: () =>
          import("@/views/programme/childPages/Technical.vue"),
        meta: {
          title: "技术管理",
        },
      },
     {
        path: "/propramme/cloudService",
        name: "CloudService",
        component: () =>
          import("@/views/programme/childPages/cloudService.vue"),
        meta: {
          title: "Saas云服务",
        },
      },
      {
        path: "/propramme/deploymentScheme",
        name: "DeploymentScheme",
        component: () =>
          import("@/views/programme/childPages/deploymentScheme/DeploymentScheme.vue"),
        meta: {
          title: "平台私有化本地部署方案",
        },
      },
      {
        path: "/propramme/epidemic",
        name: "Epidemic",
        component: () =>
          import("@/views/programme/childPages/Epidemic.vue"),
        meta: {
          title: "疫情防控·",
        },
      },
      {
        path: "/propramme/edu-train",
        name: "EduTrain",
        component: () =>
          import("@/views/programme/childPages/EduTrain.vue"),
        meta: {
          title: "教育培训",
        },
      },
      {
        path: "/propramme/engineering",
        name: "Engineering",
        component: () =>
          import("@/views/programme/childPages/Engineering.vue"),
        meta: {
          title: "工程行业方案",
        },
      },
      {
        path: "/propramme/digitalData",
        name: "DigitalData",
        component: () =>
          import("@/views/programme/childPages/DigitalData.vue"),
        meta: {
          title: "数字资料档案管理",
        },
      }
    ]
  }
  
];

export default baseRoutes;
