/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-11-09 11:45:49
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-02-06 13:45:32
 * @FilePath: \saas_front\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css';
import '@/plugins/element';
// import VConsole from 'vconsole';

// new VConsole(); //移动端真机测试

Vue.config.productionTip = false;
import Router from 'vue-router';
console.log(document.domain)
// document.domain = "saas.bimcc.com"
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

