/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-11-10 10:33:50
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-11-10 14:30:40
 * @FilePath: \saas_front\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import saasManage from './saasManage';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...saasManage.state, // 架构切换数据
  },
  mutations: {
    ...saasManage.mutations // 架构切换操作
  },
  actions: {
  },
  modules: {
  }
})
